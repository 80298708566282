<template>
  <div ref="viewReceiptManage" class="content-wrapper view-receipt-manage pt-3">
    <div class="content">
      <b-card>
        <h4><i class="fas fa-receipt"></i> จัดการใบเสร็จ</h4>
        <div class="row mx-1 my-3">
          <b-col class="px-2" cols="12" sm="4">
            <date-picker
              class="mb-1"
              v-model="dateRange"
              type="date"
              range
              format="DD MMM YY"
              :shortcuts="dateRangeShortcuts"
              :disabled-date="notAfterToday"
              placeholder="เลือกวันที่ต้องการค้นหา"
              input-class="form-control"
              @input="searchReceipt"
            >
            </date-picker>
            <div v-if="isDateSelected" class="description text-danger">
              กรุณาเลือกวันที่
            </div>
          </b-col>
          <b-col class="px-2" cols="12" sm="4">
            <b-form-input
              type="search"
              class="col mb-1"
              autofocus
              v-model="searchTable"
              placeholder="ค้นหาจาก เลขที่ DN หรือ ชื่อคนไข้"
              @change="searchReceipt"
              lazy
            ></b-form-input>
          </b-col>

          <b-col class="px-2" cols="12" sm="4">
            <b-form-group
              class="mb-2"
              label="สถานะใบเสร็จ:"
              label-cols="5"
              label-align="right"
            >
              <b-form-select
                v-model="receiptStatus"
                :options="receiptStatusOpt"
                @input="searchReceipt"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>

        <b-table
          id="receiptManageTable"
          ref="receiptManageTable"
          hover
          small
          sort-icon-left
          sort-by="id"
          :sort-desc="true"
          head-variant="light"
          :fields="fields"
          :items="receiptList"
          responsive
        >
          <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
          <template v-slot:cell(creationDt)="row">{{
            formatDate(row.value)
          }}</template>
          <template #cell(dn)="row"
            ><span class="narrow-spacing"
              >{{ row.item.patient ? row.item.patient.dn : ""
              }}<span class="small-text text-muted">{{
                row.item.patient && row.item.patient.existDn
                  ? "/" + row.item.patient.existDn
                  : ""
              }}</span></span
            ></template
          >
          <template v-slot:cell(firstNameTh)="row">{{
            row.item.patient
              ? row.item.patient.firstNameTh + " " + row.item.patient.lastNameTh
              : ""
          }}</template>
          <template v-slot:cell(button)="row">
            <b-button
              class="show-when-hovered"
              size="xs"
              @click="openReceiptModal(row.item)"
              ><i class="fas fa-info"></i
            ></b-button>
          </template>
          <template v-slot:cell(status)="row">
            <span
              v-if="row.item.cancel"
              v-b-popover.hover.bottomleft="cancelPopoverConfig(row.item)"
            >
              <i class="fas fa-ban text-danger"></i>
            </span>
          </template>
          <template v-slot:custom-foot v-if="isTableLoading">
            <b-tr>
              <b-td colspan="12" class="text-center mt-2">
                <b-spinner variant="primary"></b-spinner>
              </b-td>
            </b-tr>
          </template>
        </b-table>
      </b-card>
      <PaymentReceiptPreviewModal
        ref="paymentReceiptPreviewModal"
      ></PaymentReceiptPreviewModal>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import PaymentReceiptPreviewModal from "@/components/modal/PaymentReceiptPreviewModal";

import moment from "moment";
import debounce from "@/utils/debounce";

import { eventBus } from "../main";

export default {
  name: "ReceiptManage",
  components: {
    Dialog,
    Loading,
    PaymentReceiptPreviewModal,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "receiptNo",
          label: "เลขที่ใบเสร็จ",
          sortable: true,
        },
        {
          key: "creationDt",
          label: "วันที่ออกใบเสร็จ",
          sortable: true,
        },
        {
          key: "dn",
          label: "DN./DN.(เดิม)",
          class: "narrow-spacing",
        },
        {
          key: "firstNameTh",
          label: "ชื่อคนไข้",
        },
        {
          key: "paid",
          label: "ชำระ(บาท)",
        },

        {
          key: "status",
          label: "",
        },
        {
          key: "button",
          label: "",
        },
      ],
      searchTable: null,
      listOffset: 0,
      listlimit: 20,

      //date range picker
      dateRange: [],

      receiptStatus: null,
      receiptStatusOpt: [
        {
          value: null,
          text: "ทั้งหมด",
        },
        {
          value: "0",
          text: "ปกติ",
        },
        {
          value: "1",
          text: "ยกเลิก",
        },
      ],
      isTableLoading: false,
      debouncedHandleScroll: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      rawReceiptList: "moduleReceipt/getReceiptList",
      getReceiptTotalNo: "moduleReceipt/getReceiptTotalNo",
      isManager: "moduleUser/isManager",
      isHeadCounter: "moduleUser/isHeadCounter",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    receiptList() {
      let list = this.rawReceiptList.map(receipt => {
        return { ...receipt, _rowVariant: receipt.cancel ? "secondary" : "" };
      });
      return list;
    },

    startDt() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    endDt() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    sevenDaysAgo() {
      let date = new Date(this.today);
      date.setDate(date.getDate() - 7);
      return date;
    },
    isDateSelected() {
      return !this.startDt || !this.endDt;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchPatientById: "modulePatient/fetchPatientById",
      fetchReceiptById: "moduleReceipt/fetchReceiptById",
      fetchReceiptList: "moduleReceipt/fetchReceiptList",
      fetchReceiptScrollList: "moduleReceipt/fetchReceiptScrollList",
      verifyUser: "moduleUser/verifyUser",
    }),
    init() {},
    searchReceipt() {
      if (this.isDateSelected) return;
      this.isTableLoading = true;
      this.listOffset = 0;
      this.fetchReceiptList({
        search: this.searchTable ? this.searchTable.trim() : "",
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        offset: this.listOffset,
        limit: this.listlimit,
        startDt: this.startDt,
        endDt: this.endDt,
        status: this.receiptStatus,
      }).finally(() => {
        this.isTableLoading = false;
      });
    },
    handleScroll(e) {
      console.log("get", this.getReceiptTotalNo);
      console.log("l", this.receiptList.length);
      if (
        this.isTableLoading ||
        this.getReceiptTotalNo <= this.receiptList.length
      )
        return;
      let docEl = e.target.documentElement;

      if (docEl.scrollTop + docEl.clientHeight >= docEl.scrollHeight - 1) {
        this.isTableLoading = true;
        this.listOffset += this.listlimit;
        this.fetchReceiptScrollList({
          search: this.searchTable ? this.searchTable.trim() : "",
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          offset: this.listOffset,
          limit: this.listlimit,
          startDt: this.startDt || null,
          endDt: this.endDt || null,
          status: this.receiptStatus,
        }).finally(() => {
          this.isTableLoading = false;
        });
      }
    },
    openReceiptModal(data) {
      const receiptPromise = this.fetchReceiptById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: data.id,
      });
      const patientPromise = this.fetchPatientById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: data.patientId,
      });

      this.isLoading = true;
      Promise.all([receiptPromise, patientPromise])
        .then(responseAll => {
          if (responseAll) {
            const [receiptRes, patientRes] = responseAll;

            if (receiptRes.status == 200 && patientRes.status == 200) {
              if (receiptRes.data.id && patientRes.data.id) {
                this.$refs.paymentReceiptPreviewModal.show(
                  receiptRes.data,
                  patientRes.data,
                  data.cancel ? "cancel" : "copy",
                  this.isManager || this.isHeadCounter
                );
              } else {
                eventBus.$emit("alertSwal", {
                  title: "ระบบขัดข้อง",
                  message: "ไม่สามารถแสดงข้อมูลใบเสร็จได้",
                  icon: "error",
                });
              }
            }
          }
        })
        .catch(err => {
          console.error("catch:", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatTime(date) {
      if (date) {
        return moment(date).locale("th").format("HH:mm");
      } else {
        return "";
      }
    },
    cancelPopoverConfig(e) {
      return {
        html: true,
        variant: "danger",
        title: () => {
          return `ใบเสร็จ ${e.receiptNo} ถูกยกเลิก`;
        },
        content: () => {
          return `โดย:<em> ${e.cancelFirstNameTh ?? ""} ${
            e.cancelLastNameTh ?? ""
          }</em><br>
          วันเวลา:<em> ${this.formatDate(e.cancelDt) ?? ""} ${
            this.formatTime(e.cancelDt) ?? ""
          }</em><br>
          สาเหตุ:<em> ${e.cancelRemark ?? ""}</em>`;
        },
      };
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    this.debouncedHandleScroll = debounce(this.handleScroll, 300);
    window.addEventListener("scroll", this.debouncedHandleScroll);
    this.dateRange = [this.sevenDaysAgo, this.today];
    this.searchReceipt();
  },
  updated() {},
  destroyed() {
    window.removeEventListener("scroll", this.debouncedHandleScroll);
  },
};
</script>

<style scoped>
</style>
